<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>
        <div class="subheader">
           <h3>Acerca de</h3>
        </div>
        <div class="container" style="margin-top:30px">
            <div class="row">
            <div class="col-md-7 col-sm-12 text-justify">
                <h5>Top Managment Training</h5>
                <p>Somos una empresa enfocada a brindar soluciones en el ámbito de la capacitación a ejecutivos en desarrollo y/o empresarios, con un enfoque no solo bajo un marco teórico sino sustentado en la experiencia de personas que se han desarrollado en ese ámbito en el mundo empresarial, buscando siempre un beneficio tanto para las personas que toman nuestros programas así como para las empresas que desean capacitar a sus colaboradores, y que puedan tener un retorno de la inversión en sus actividades de manera directa.</p>
                <h5 class="mt-5">Nuestra Misión</h5>
                <p>
                    Transferir conocimiento aplicado con un marco teórico a:
                    <ul>
                        <li>Personas interesadas a obtener mejores oportunidades, al contar con mejor preparación en temas relevantes, interactuando con tutores que destacan en el medio empresarial.</li>
                        <li>Empresas que estén conscientes que la capacitación es una de las mejores formas de retener y desarrollar talento, para que puedan ver impactados sus procesos de forma inmediata.</li>
                    </ul>
                </p>
                <h5 class="mt-5">Nuestra Visión</h5>
                <ul>
                    <li>Usar los métodos digitales de aprendizaje para hacer procesos flexibles.</li>
                    <li>Tener tutores relevantes en el sector industrial, para transferir experiencia de los conocimientos de forma practica no solo teórica.</li>
                    <li>Reconocer, si así lo permitiesen, a quienes concluyan nuestros programas de capacitación en nuestras redes sociales para que tengan visibilidad para empresas en busca de profesionales preparados.</li>
                    <li>Facilitar el Networking tanto con los tutores como con los participantes.</li>

                </ul>
                <h5 class="mt-5">Nuestra Política</h5>
                <ul class="mb-5">
                    <li>Nos comprometemos a ofrecerte programas flexibles, con un alto estándar en la selección de nuestros tutores y con la mejor calidad en el desarrollo de los cursos en línea.</li>
                    <li>Consideramos la mejora continua, así como la identificación y cumplimiento los requisitos aplicables como parte de nuestros procesos.</li>
                    <li>Nuestra propuesta de valor siempre considerara respeto hacia el medio ambiente y por nuestras partes interesadas.</li>
                </ul>

            </div>
            <div class="col-md-5 d-none d-sm-block"><img src="@/assets/img/about-us.jpg" /></div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components:{
      Header,
      Footer
  },
  name: 'AboutUs',
  data() {
      return {
          profile: this.$store.getters.getUser
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}

.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px;
   background-image :url(../../assets/img/bg-acerca-de.jpg);
   background-repeat: no-repeat;
  background-size: cover;
  }
</style>
